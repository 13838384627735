import React, { useState } from 'react'
import SelectAirport from './SelectAirport'
import SelectDateRange from './SelectDateRange'
import Notification from './Notification'
import SearchIcon from '@mui/icons-material/Search'
import { initialFromDate, initialToDate, formatDate } from '../helpers/date-helper'

function SearchApp() {
  const [airport, setAirport] = useState()
  const [dates, setDates] = useState([formatDate(initialFromDate), formatDate(initialToDate)])
  const [triggerAirportError, setTriggerAirportError] = useState(false)
  const [triggerDatesError, setTriggerDatesError] = useState(false)

  // Conditional checks based on URL
  let pathname = window.location.pathname
  let airportCodeInUrl = ''
  if (!pathname.endsWith('/')) {
    pathname = pathname + '/'
  }
  const isAirportPage = pathname.includes('/airport/')
  if (isAirportPage) {
    airportCodeInUrl = pathname.slice(-4, -1)
  }

  const handleClick = () => {
    if (airport && dates && !triggerAirportError && !triggerDatesError) {
      window.location.href = `https://parkobility.com/app/search?airport=${airport}&from_date=${dates[0]}&to_date=${dates[1]}`
    } else {
      if (!airport) {
        setTriggerAirportError(true)
        setTimeout(() => {
          setTriggerAirportError(false)
        }, 5000)
        return
      }
      if (!dates) {
        setTriggerDatesError(true)
        setTimeout(() => {
          setTriggerDatesError(false)
        }, 5000)
        return
      }
    }
  }

  return (
    <div className="flex flex-col">
      <div
        className={`font-nunito flex flex-col gap-2 self-center ${
          isAirportPage ? 'mb-3 w-full' : ' lg:flex-row lg:w-3/4 lg:items-end p-2 w-11/12'
        }`}
      >
        <div className={`basis-full ${isAirportPage ? '' : ' md:basis-2/3'}`}>
          <SelectAirport
            setAirport={setAirport}
            defaultAirport={airportCodeInUrl}
            isAirportPage={isAirportPage}
          />
        </div>
        <div className={`basis-full ${isAirportPage ? '' : ' md:basis-1/3'}`}>
          <SelectDateRange setDates={setDates} isAirportPage={isAirportPage} />
        </div>
        <button
          onClick={handleClick}
          className={`bg-pb-primary hover:bg-primary border-0 shadow-none !rounded py-1.5 px-1 mb-0.25 mt-5 cursor-pointer ${
            isAirportPage ? 'mt-1' : 'lg:mt-0'
          }`}
          aria-label="Search"
        >
          <SearchIcon sx={{ fontSize: 33 }} />
        </button>
      </div>
      <Notification
        severity="error"
        message="Please select departure airport"
        isOpen={triggerAirportError}
      />
      <Notification
        severity="error"
        message="Please select date range"
        isOpen={triggerDatesError}
      />
    </div>
  )
}

export default SearchApp
