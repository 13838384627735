import { useState, useEffect } from 'react'
import Select from 'react-select'
import airports from '../data/airports.json'
import parseAirports from '../helpers/parse-airports-data'
import PinDropIcon from '@mui/icons-material/PinDrop'

const airportsWithCode = parseAirports(airports)

function SelectAirport({ setAirport, defaultAirport, isAirportPage }) {
  // Select default option object from default airport code
  let defaultAirportOption = null
  if (defaultAirport) {
    const defaultOptionArray = airportsWithCode.filter(
      airport => airport.value.toLowerCase() === defaultAirport.toLowerCase()
    )
    if (defaultOptionArray.length) {
      defaultAirportOption = defaultOptionArray[0]
    }
  }

  // set Apps airportcode if default prop is given
  useEffect(() => {
    if (isAirportPage && defaultAirportOption) {
      setAirport(defaultAirportOption.value)
    }
  }, [defaultAirport])

  const [selectedOption, setSelectedOption] = useState(defaultAirportOption)

  const handleChange = option => {
    if (option) {
      setSelectedOption(option)
      setAirport(option.value)
    } else {
      setSelectedOption(null)
      setAirport(null)
    }
  }

  const filterOption = (option, inputValue) => {
    const { label, value, city, state } = option.data
    const filterValue = inputValue.toLowerCase()

    return (
      label?.toLowerCase().includes(filterValue) ||
      city?.toLowerCase().includes(filterValue) ||
      state?.toLowerCase().includes(filterValue)
    )
  }

  return (
    <div>
      <label
        htmlFor="airport-selector"
        className={`block mb-2 ${
          isAirportPage ? 'text-pb-secondary font-bold' : 'text-pb-primary'
        }`}
      >
        Departure airport
      </label>
      <div className="relative">
        <PinDropIcon className="absolute z-30 top-3 left-3 text-pb-secondary" />
        <Select
          inputId="airport-selector"
          defaultValue={selectedOption}
          onChange={handleChange}
          options={airportsWithCode}
          filterOption={filterOption}
          placeholder="Airport Name, City or Code"
          noOptionsMessage={() => 'No airports found'}
          isClearable={true}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              padding: '5px',
              paddingLeft: '35px'
            })
          }}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: 'rgba(0,0,0,0.15)',
              primary25: '#f5f5f5',
              primary50: '#f5f5f5'
            }
          })}
          className="z-20"
        />
      </div>
    </div>
  )
}

export default SelectAirport
