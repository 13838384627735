const initialFromDate = new Date()
const today = new Date()
const initialToDate = new Date(today.setDate(today.getDate() + 7))

function formatDate(inputDate) {
  return inputDate
    .toLocaleDateString('en-gb', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
    .split('/')
    .reverse()
    .join('-')
}

export { formatDate, initialFromDate, initialToDate }
