function parseAirports(airports) {
  const airportsWithCode = airports.map(airport => {
    // Extract airport code from airport name and add as a property
    return {
      value: airport.name.slice(-4, -1),
      label: airport.name,
      city: airport.address.city,
      state: airport.address.state,
      country: airport.address.country
    }
  })

  return airportsWithCode
}

export default parseAirports
