import React, { useState } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import './SelectDateRange.css'
import './DateRangePicker.css'
import './Calendar.css'
import TodayIcon from '@mui/icons-material/Today'
import { initialFromDate, initialToDate, formatDate } from '../helpers/date-helper'

function SelectDateRange({ setDates, isAirportPage }) {
  const [selectedDates, setSelectedDates] = useState([initialFromDate, initialToDate])

  const handleChange = option => {
    if (option) {
      setSelectedDates(option)
      const formattedDate = option.map(item => formatDate(item)) // yyyy-mm-dd
      setDates(formattedDate)
    } else {
      setSelectedDates(null)
      setDates(null)
    }
  }

  return (
    <>
      <label
        className={`block mb-2 ${
          isAirportPage ? 'text-pb-secondary font-bold' : 'text-pb-primary'
        }`}
      >
        {isAirportPage ? 'Parking Check-In / -Out' : 'Date'}
      </label>
      <div className="relative">
        <TodayIcon className="absolute z-10 top-3 left-3 text-pb-secondary" />
        <DateRangePicker
          onChange={handleChange}
          value={selectedDates}
          minDate={initialFromDate}
          locale="en-US"
          isOpen={false}
          clearIcon={null}
          calendarIcon={false}
          className="bg-white p-2 border-solid border-gray-300 rounded w-full"
          calendarClassName="bg-white"
          calendarAriaLabel="Toggle calendar"
          dayAriaLabel="Day"
          monthAriaLabel="Month"
          nativeInputAriaLabel="Date"
          yearAriaLabel="Year"
        />
      </div>
    </>
  )
}

export default SelectDateRange
