import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import SearchApp from './components/SearchApp'

const searchWrapper = document.getElementById('airport-search-wrapper')

if (searchWrapper) {
  const searchRoot = ReactDOM.createRoot(searchWrapper)
  searchRoot.render(
    <React.StrictMode>
      <SearchApp />
    </React.StrictMode>
  )
}
